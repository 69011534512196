<template>
  <div>
    <button
      @click="clickClose"
      type="button"
      class="btn btn-danger close-button"
      data-toggle="button"
      aria-pressed="false"
      autocomplete="off"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    clickResize() {
      this.$emit("resize");
    },
    clickClose() {
      if (this.getWebspeech) {
        this.getWebspeech.stop();
      }
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters(["getWebspeech"]),
  },
};
</script>
<style scoped>
div {
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 9999;
  overflow: visible;
}
button {
  font-size: 20px;
  line-height: 20px;
  padding: 0 5px;
  margin: 0;
  font-weight: bold;
  border-radius: 100px;
}
.resize-button {
  font-size: 12px;
  margin-right: 3px;
}
</style>
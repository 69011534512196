<template>
  <button class="btn btn-primary" @click="openFilePicker">Upload</button>
</template>
<script>
import * as filestack from "filestack-js";
import { mapGetters } from "vuex";

const filestackClient = filestack.init("A7X1jv8ZSZOtv8qBfSr3gz");

export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getDefaults"]),
    canUpload() {
      return (
        this.getDefaults.image_upload_count <
        this.getDefaults.image_upload_limit
      );
    },
  },
  methods: {
    openFilePicker() {
      const options = {
        maxFiles: 1,
        uploadInBackground: false,
        onOpen: this.onOpen,
        onUploadDone: this.onUploadDone,
      };
      if (this.canUpload) {
        filestackClient.picker(options).open();
      }
    },
    onOpen() {
      // console.log(this.item);
    },
    onUploadDone(results) {
      const { filesUploaded } = results;
      this.$store.dispatch("uploadFilestack", [
        JSON.stringify(filesUploaded),
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
        },
      ]);
      this.$emit("done", filesUploaded);
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      ["image_upload_count", "image_upload_limit"],
      () => {},
    ]);
  },
};
</script>
<style>
.tooltip {
  opacity: 0.5;
}
</style>